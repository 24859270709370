<template lang="pug">
  #timer-button
    button.timer-button(:class="{ 'timer-running': timerRunning }" @click="handleClick" :disabled="isDisabled")
      icon.timer-icon(v-if="timerRunning" data="@icon/timer.svg")
      .button-text {{ buttonText }}
      icon.arrow-icon(v-if="timerComplete" data="@icon/arrow-forward.svg")
</template>
<script>
export default {
  props: {
    canStartTimer: {
      type: Boolean,
      required: true,
    },
    autoStart: {
      type: Boolean,
      required: false,
      default: false,
    },
    duration: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      timerRunning: false,
      timerDoneSound: null,
      timeRemaining: 0,
    }
  },

  mounted() {
    this.timeRemaining = this.duration
    const audioSrc = require(`@/assets/audio/timer-done.mp3`)
    this.timerDoneSound = new Audio(audioSrc)

    if (this.autoStart) {
      this.startTimer()
    }
  },

  computed: {
    buttonText,
    isDisabled,
    timerComplete,
  },

  watch: {},

  methods: {
    handleClick,
    getCountdownText,
    startTimer,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function buttonText() {
  return this.timerRunning ? this.getCountdownText() : this.timerComplete ? 'Next' : 'Start Timer'
}

function isDisabled() {
  return this.timerRunning || !this.canStartTimer
}

function timerComplete() {
  return this.timeRemaining <= 0
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function handleClick() {
  if (this.timerComplete) {
    this.$refs.timerVideo?.pause()
    this.$emit('complete')
  } else {
    this.startTimer()
  }
}

function getCountdownText() {
  function padZero(num) {
    return Math.floor(num)
      .toString()
      .padStart(2, 0)
  }

  return `${padZero(this.timeRemaining / 60)}:${padZero(this.timeRemaining % 60)}`
}

function startTimer() {
  this.timerRunning = true

  let tick = setInterval(() => {
    this.timeRemaining -= 1

    if (this.timerComplete) {
      this.timerRunning = false
      this.timerDoneSound.play()
      clearInterval(tick)
    }
  }, 1000)
}
</script>
