<template lang="pug">
#video-header
  VideoStepNavigation(
    :class="{ 'is-visible': showPopup }",
    :data="data",
    :videoProgressMarker="videoProgressMarker",
    :currentVideoIndex="currentVideoIndex",
    @gotoVideo="goToVideo",
    @hideNavPopup="hideNavPopup"
  )
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'

export default {
  props: {
    title: { type: String, required: true },
    prompt: { type: String, required: true },
    step: { type: String },
    canGoNext: { type: Boolean, required: true },
    actionButton: { type: Object, required: true },
    data: { type: Array, required: true, default: () => [] },
    videoProgressMarker: { type: Number, required: false, default: 0 },
    currentVideoIndex: { type: Number, required: false, default: 0 },
    nudgeActive: { type: Boolean, required: false, default: false },
    // stepIndex used to set a key on the timer button so it won't be reused in the next step
    stepIndex: { type: Number, required: true },
    proceededWithoutVideo: { type: Boolean, required: true },
  },

  data() {
    return {
      showPopup: false,
      stepTimeStamp: Date.now(),
    }
  },

  computed: {},

  methods: {
    goToVideo,
    hideNavPopup,
    showNavPopup,
  },

  components: {
    VideoStepNavigation: require('@/components/VideoStepNavigation').default,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goToVideo(videoIndex) {
  this.showPopup = false
  this.$emit('gotoVideo', videoIndex)
}
function hideNavPopup() {
  this.showPopup = false
  // give the nav popup time to disappear before resuming
  setTimeout(() => {
    this.$emit('resumeVideo')
  }, 500)
}

function showNavPopup() {
  this.showPopup = true
  this.$emit('pauseVideo')
  const eventTitle = 'Menu'
  const eventProperties = {
    segment: this.title,
    step: this.prompt,
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    segment_time: Date.now() - this.stepTimeStamp,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })
}
</script>
