<template lang="pug">
  #video-step-navigation(:class="componentClasses")

    #vsn-sections
      .vsn-section(
        v-for="(section, index) in videoNav"
        :class="{'is-current-section': index == selectedSectionIndex}"
        @click="setSection(index)") {{ section.title }}

    #vsn-steps(v-touch:swipe.down="handleSwipeDown")
      template(v-for="(section, sectionIndex) in videoNav")
        .vsn-section-step(
          v-for="(video, index) in section.steps"
          :class="{'is-current-step': isCurrentStep(video.videoIndex), 'is-visitable': isVisitable(video.videoIndex)}"
          @click="goToVideo(video.videoIndex)"
          v-show="sectionIndex == selectedSectionIndex"
        )

          icon.current-step(v-if="isCurrentStep(video.videoIndex)" data="@icon/arrow-forward.svg")
          icon.next-step(v-else-if="video.videoIndex == videoProgressMarker" data="@icon/radio-button-unchecked.svg")
          icon.can-visit(v-else-if="canVisitStep(video.videoIndex)" data="@icon/check.svg")
          .no-visit(v-else) {{ index + 1 }}.

          .step-text {{ video.prompt }}

    #close-popup(@click="hideNavPopup()")
      icon(data="@icon/close.svg")

    button#resume-kit(@click="hideNavPopup") Resume
</template>

<script>
export default {
  props: {
    data: { type: Array, required: true, default: () => [] },
    videoProgressMarker: { type: Number, required: false, default: 0 },
    currentVideoIndex: { type: Number, required: false, default: 0 },
  },

  data() {
    return {
      selectedSectionIndex: 0,
      videoNav: [],
      videoSectionLookup: [],
    }
  },

  computed: {
    componentClasses,
  },

  watch: {
    currentVideoIndex: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.selectedSectionIndex = this.videoSectionLookup[newVal]
        }
      },
    },
  },

  created() {
    this.buildVideoNavigation()
  },

  mounted() {
    // Set initial selected section
    this.selectedSectionIndex = this.videoSectionLookup[this.currentVideoIndex]
  },

  methods: {
    buildVideoNavigation,
    canVisitStep,
    handleSwipeDown,
    goToVideo,
    hideNavPopup,
    isCurrentStep,
    isVisitable,
    setSection,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function componentClasses() {
  const currentSection = this.videoNav[this.selectedSectionIndex]
  var sectionTitle = currentSection?.title

  return sectionTitle?.toLowerCase().replace(/\s/g, '-')
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function buildVideoNavigation() {
  this.data.forEach((video, index) => {
    //  skip coachmarks
    if (video.coachMark) {
      return
    }

    // For each video, if the title matches the title of the last entry in the videoNav list,
    // push it into that entry's step list. Other wise, create a new entry in videoNav
    const videoInfo = { videoIndex: index, prompt: video.prompt }
    if (video.title == this.videoNav[this.videoNav.length - 1]?.title) {
      this.videoSectionLookup[index] = this.videoNav.length - 1
      this.videoNav[this.videoNav.length - 1].steps.push(videoInfo)
    } else {
      this.videoSectionLookup[index] = this.videoNav.length
      this.videoNav.push({ title: video.title, steps: [videoInfo] })
    }
  })
}

function canVisitStep(videoIndex) {
  return videoIndex <= this.videoProgressMarker && videoIndex !== this.currentVideoIndex
}

function handleSwipeDown() {
  this.hideNavPopup()
}

function goToVideo(videoIndex) {
  if (this.canVisitStep(videoIndex)) {
    this.selectedSectionIndex = this.videoSectionLookup[videoIndex]
    this.$emit('gotoVideo', videoIndex)
  }
}

function hideNavPopup() {
  this.$emit('hideNavPopup')
  setTimeout(() => {
    this.selectedSectionIndex = this.videoSectionLookup[this.currentVideoIndex]
  }, 500)
}

function isCurrentStep(stepIndex) {
  return stepIndex == this.currentVideoIndex
}

function isVisitable(stepIndex) {
  return stepIndex <= this.videoProgressMarker
}

function setSection(index) {
  this.selectedSectionIndex = index
}
</script>
