<template lang="pug">
  #connection-warning.app-modal-wrapper
    .app-modal
      .app-modal-bg
      keep-alive 
        .modal-content
          .video-step-dialog.is-fullscreen
            .modal-header
              h2 Disconnected/Poor Network Connection

            .modal-text
              icon#warning-icon(:data="require('@icon/wifi.svg')")
              p Please check your network connection and make sure you're connected to WiFi if available. If you're already on WiFi, moving closer to the router can improve your connection.
            
            .modal-footer
              button.button.action-btn(@click="reconnect") Reconnect
</template>

<script>
export default {
  props: {
    action: { type: Function, required: false },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {
    reconnect,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function reconnect() {
  this.action()
}
</script>
